import React, { Fragment, useEffect } from 'react';
import {RevealHeadlines} from './animation';
import gsap from 'gsap';
import {ReactComponent as MyConetLogoComponent} from '../assets/my-conet.logo.svg';
import {ReactComponent as FoxxuLogoComponent} from '../assets/foxxu-logo.svg'; 

const Content = {
    headline: "Unsere Markenallianz",
    content: ["Mit der Markenallianz von my-conet und foxxu bieten wir für Sie in Mainfranken den Standortvorteil durch unsere Ansprechpartner vor Ort in Würzburg, das Leistungsspektrum beider Marken und ein großes Experten-Netzwerk.","Als Ihr zuverlässiger strategischer Partner im Bereich der Personalentwicklung gestalten wir mit Ihnen vertrauensvoll und nachhaltig die Zukunft Ihres Unternehmens und das Wachstum der Kompetenzen seiner Menschen."]

};
export default function Markenallianz(){

   
    useEffect(() => {
        window.scrollTo(0,0);
        const tl = gsap.timeline();  
  



            tl.to(".overlay__loading", .4, {
                opacity: 0,
                display: 'none',
                delay: .6
            })
            .to(".method__site", {visibility: "visible"})
            .from(".section__headline", .6, {
                opacity:0,
                y: '150%',
                ease: "power4.ease"
            })
            .from(".method__site__content__p", .6, {
                opacity: 0,
                y: 100,
                ease: "power4.ease"
            })
            .from(".method__site__list",.6, {
                y:100,
                opacity: 0,
                ease: "power4.ease",
                stagger: {
                    amount: .3
                }
            })
      
        
    }, []);

    return(
        <Fragment>
        <div className="overlay__loading"></div>
        <div className="container-fluid mt-menu method__site">
        <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2><span className="block__element"><RevealHeadlines>Unsere </RevealHeadlines></span>
                        <span className="block__element"><RevealHeadlines>Markenallianz </RevealHeadlines></span></h2>
                    </div>
                    </div>
                </div>
            <div className="row">
                <div className="col-lg-7 offset-lg-4 offset-md-3 method__site__content">
                    {Content.content.map(item => {
                        return(
                            <p className="method__site__content__p">
                                {item}
                            </p>    
                        );
                    })}
                    
                </div>
            </div>
            <div className="row">
                <div className="col-6 marken_button d-flex align-items-center justify-content-center">
                    <a href="https://www.foxxu.de"><FoxxuLogoComponent/></a>
                </div>
                <div className="col-6 marken_button d-flex align-items-center  justify-content-center">
                <a href="http://my-conet.de/"><MyConetLogoComponent/></a>
                </div>
            </div>
        </div>
        </Fragment>

        
    );
}

