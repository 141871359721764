import React, { useEffect, Fragment } from 'react';
import {RevealHeadlines, RevealCards} from './animation';
import {ReactComponent as Arrow__Skew} from '../assets/Arrow_skew.svg'; 
import gsap from 'gsap';

import {useHistory} from 'react-router-dom';

const MethodsItems = [{name:"Assessment", path:"assessment"}, {name:"Coaching", path:"coaching"}, {name:"Training", path:"training"}, {name:"Workshops", path:"workshop"}];

function MethodsOverview(){

    useEffect(() => {

        window.scrollTo(0,0);
        const tl = gsap.timeline();  



            tl.to(".overlay__loading", .4, {
                opacity: 0,
                display: 'none',
                delay: .6
            })
            .to(".method__site", {visibility: "visible"})
            .from(".section__headline", .6, {
                opacity:0,
                y: '150%',
                ease: "power4.ease"
            })
            .from('.method__content', .7, {
                opacity: 0,
                y: '100%',
                ease: 'expo.in'
            })
      
        
    }, []);

    const  history  = useHistory();
    function handleClick(item) {

        
        setTimeout(()=> {
            window.scroll(0,0);
            history.push(`/methods/${item.path}`);
        }, 600);
    };


    return(
        <Fragment>
        <div className="overlay__loading"></div>
        <div className="container-fluid mt-menu method__site">
        <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2><span className="block__element"><RevealHeadlines>Unsere </RevealHeadlines></span>
                        <span className="block__element"><RevealHeadlines>Methoden </RevealHeadlines></span></h2>
                    </div>
                    </div>
                </div>
        </div>
        <div className="container-fluid method__content">
                <div className="row">
                {MethodsItems.map((item,index) => {
                    return(
                       
                        <div className="col-12 col-lg-9 offset-lg-1">
                          <div className="methods__link" onClick={(event) => handleClick(item)}>
                        <div className="link__action d-flex justify-content-between"> 
                           <span className="d-flex shuffle_p">{item.name}   </span> 
                           
                        </div>
                  
                        </div>
                            </div>
                       
                    );
                })}
                </div>
                
            </div>
            </Fragment>
    );
}


export default MethodsOverview;