import React, { Fragment, useEffect } from 'react';
import {RevealHeadlines} from './animation';
import gsap from 'gsap';


const punkte  = [ 
    {
        name:"Analysieren",
        content:"Wir analysieren Prozesse, Strukturen und Zusammenarbeit in Ihrem Unternehmen.",
    },
    {
        name:"Beraten",
        content:"Wir beraten unabhängig in allen Prozessfragen.",
    },
    {
        name:"Konzipieren",
        content:"Wir konzipieren und produzieren größere und kleinere Maßnahmen und Werkzeuge für Ihre Veränderungsvorhaben, Ihre Personalarbeit und Trainings.",
    },
    {
        name:"Qualifizieren",
        content:"Wir qualifizieren Führungskräfte und Mitarbeiter in ihrer fachlichen und persönlichen Entwicklung weiter.",
    },
    {
        name:"Evaluieren",
        content: "Wir messen die Zielverfolgung, zeigen Veränderungen und Effekte auf und machen Erfolge sichtbar."
    }
    
]


function UnserWeg(){
    useEffect(()=> {

        window.scrollTo(0,0);
        const tl = gsap.timeline();  



        tl.to(".overlay__loading", .4, {
            opacity: 0,
            display: 'none',
            delay: .6
        })
        .to(".method__site", {visibility: "visible"})
        .from(".section__headline", .6, {
            opacity:0,
            y: '150%',
            ease: "power4.ease"
        })
        .from(".unser__weg__punkt", .7, {
            opacity: 0,
            y: 100,
            ease: "expo.out",
            stagger: {
                amount: .4
            }
        })
    }, [])
    return(
        <Fragment>

             <div className="overlay__loading"></div>
        <div className="container-fluid mt-menu method__site">
        <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2><span className="block__element"><RevealHeadlines>Unser Weg </RevealHeadlines></span>
                        <span className="block__element"><RevealHeadlines>mit Ihnen </RevealHeadlines></span></h2>
                    </div>
                    </div>
                </div>
        </div>
        <div className="container-fluid unser__weg">
                <div className="row">
                {punkte.map(punkt => {
                        return(
                            <div className="unser__weg__punkt col-lg-11 offset-lg-1">
                            <div className="row">
                            <div className="col-lg-4 unser__weg__headline">
                                <h3>{punkt.name}</h3>
                            </div>
                            <div className="col-lg-4 unser__weg__content offset-lg-1">
                                <p>{punkt.content}</p>
                            </div>
                            </div>
                        </div>
                        )
                    })}
                    
                    
                        
                </div>
            </div>

        </Fragment>
    );
}

export default UnserWeg;