import React, {useEffect, Fragment} from 'react';
import times from 'lodash/times';
import Marquee, {Motion, randomIntFromIntervall} from 'react-marquee-slider';

import {ReactComponent as Aok} from '../assets/clients/aok.svg';
import {ReactComponent as Audi} from '../assets/clients/audi.svg';
import {ReactComponent as Bsh} from '../assets/clients/bsh.svg';
import {ReactComponent as Daimler} from '../assets/clients/daimler.svg';
import {ReactComponent as Edeka} from '../assets/clients/edeka.svg';
import {ReactComponent as Generali} from '../assets/clients/generali.svg';
import {ReactComponent as Leoni} from '../assets/clients/leoni.svg';
import {ReactComponent as Neff} from '../assets/clients/neff.svg';
import {ReactComponent as Ohm} from '../assets/clients/ohm.svg';
import {ReactComponent as Schaefer} from '../assets/clients/schaefer.svg';
import {ReactComponent as Skf} from '../assets/clients/skf.svg';
import {ReactComponent as Vogeldruck} from '../assets/clients/vogeldruck.svg';
import {ReactComponent as Warema} from '../assets/clients/warema.svg';




export default function Kunden(){
    return(
        <Fragment>
            <Marquee velocity={30} resetAfterTries={200} className="client__marquee">
                <div className="client">
                    <Aok/>                
                </div>
                <div className="client">
                    <Audi/>                
                </div>
                <div className="client">
                    <Bsh/>                
                </div>
                <div className="client">
                    <Daimler/>                
                </div>
                <div className="client">
                    <Edeka/>                
                </div>
                <div className="client">
                    <Generali/>                
                </div>
                <div className="client">
                    <Leoni/>                
                </div>
                <div className="client">
                    <Neff/>                
                </div>
                <div className="client">
                    <Ohm/>                
                </div>
                <div className="client">
                    <Schaefer/>                
                </div>
                <div className="client">
                    <Skf/>                
                </div>
                <div className="client">
                    <Vogeldruck/>                
                </div>
                <div className="client">
                    <Warema/>                
                </div>
            </Marquee>
            </Fragment>
    );
}