import React, { Fragment, useEffect } from 'react';
import {HashRouter, Switch, Route} from 'react-router-dom';
import Headline from './components/Headline';
import Landing from './components/Landing';
import Allianz from './components/Allianz';
import Way from './components/Way';
import Methods from './components/Methoden';
import Partner from './components/Partner';
import MethodenSeite from './components/MethodenSeite';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import UnserWeg from './components/UnserWeg';
import MethodsOverview from './components/MethodenOverview';
import Markenallianz from './components/MarkenAllianz';
import Contact from './components/Contact';
import Footer from './components/Footer';
const Methoden = [
  {
    name: "Assessment",
    content: "Zur Auswahl, Potentialanalyse und Orientierung (Standortbestimmung) Ihrer Führungskräfte und Mitarbeiter begleiten wir Assessment Verfahren von der Anforderungsanalyse und Konzeption, über Durchführung und Feedback, bis zur Gutachtenerstellung und Nachbereitung. Ob Strukturierte Interviews, verhaltensorientierte Simulationen oder psychometrische Tests zur Anwendung kommen sind wir den Standards des Forum Assessment e.V. und der DIN33430:2016-07 verpflichtet.",
    list: [],
    path:"/methods/assessment",
    
  },
  {
    name: "Coaching",
    content: "Counseling betrachten wir individuell als Beratung des Einzelnen, der Gruppe oder des gesamten Teams in Entwicklungs- oder Change-Prozessen und Problemsituationen. Wir begleiten Ihren Veränderungsprozess von der Analyse der Problemsituation über die Sammlung und gemeinsame Auswahl geeigneter Problemlösestrategien bis zum Transfer in den beruflichen und privaten Alltag hinein, hin zur Festigung neuer Denk- und Handlungsweisen.",
    list:[],
    path:"/methods/coaching"
  },
  {
    name: "Training",
    content:"Alle Trainings schneiden wir individuell auf Ihre jeweilige Zielgruppe zu. Wir arbeiten mit verschiedenen Trainingsformen:",
    list:["Erfahrungsintensive Verhaltenstrainings","Live-Online-Trainings", "Präsenzseminare", "Tele-Tutoring als individuelle Praxisbegleitung", "Virtuelle Räume, Videofeedback"],
    path:"/methods/training"
  },
  {
    name:"Workshop",
    content:"In Workshops schaffen wir den moderierten Rahmen für Sie, um in Gruppen intensiv an einem Thema zu arbeiten. Durch methodische Vielfalt regen wir kreative Prozesse an, begleiten Sie auf dem Weg der Lösungskonstruktion und bringen mit Ihnen die Ideen durch gezielte Maßnahmen in die Praxis.",
    list: [],
    path: "/methods/workshop"
  }
];

function App() {


  return (
    <Fragment>
      
    <HashRouter>
      <Headline/>
      <Switch>
      
        <Route exact path="/" component={StartSeite}/>
        <Route exact path="/methods" component={MethodsOverview}/>
        <Route exact path="/markenallianz" component={Markenallianz}/>
        {Methoden.map(({name, content, list, path}) => {
          return(
            <Route  key={name} path={path}>
            <MethodenSeite headline={name} content={content} list={list}/>         
          </Route>
         
          );
          
        })}
        <Route exact path="/kontakt" component={Contact}/>
          <Route path="/unser-weg-mit-ihnen" >
            <UnserWeg/>
          </Route>
          <Route path="/impressum" exact >
            <Impressum/>
          </Route>
          <Route path="/datenschutz" exact>
            <Datenschutz/>
          </Route>
       
        
        
      </Switch>
      
      <Footer/>
      </HashRouter>
      
      </Fragment>
  );
}





function StartSeite(){

  useEffect(() => {
    window.scroll(0,0);

    

    
  }, []);
  
  return(
    <Fragment>
  
    <Landing/>
    <Allianz/>
    <Way/>
    <Methods/>
    <Partner/>
    </Fragment>
    
    
  );
}


export default App;