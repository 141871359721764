import React from 'react';
import {RevealHeadlines, RevealText, RevealCards} from './animation';
import {ReactComponent as MyConetLogoComponent} from '../assets/my-conet.logo.svg';
import {ReactComponent as FoxxuLogoComponent} from '../assets/foxxu-logo.svg';

export default function Allianz(){
    return(
       <section className="allianz mt-20">
           <div class="container-fluid">
               <div className="row"> 
                   <div className="col-12 col-lg-11 offset-lg-1">  
                   <div className="section__headline">
                        <h2><span className="block__element"><RevealHeadlines>Unsere</RevealHeadlines></span><span className="block__element"><RevealHeadlines>Markenallianz</RevealHeadlines></span></h2>
                    </div>
                   </div>
                   <div className="col-12 col-lg-11 offset-lg-1">
                       <div className="section__flow">
                           <RevealText classes="leading">Mit der Markenallianz von my-conet und foxxu bieten wir für Sie in Mainfranken den 
                              Standortvorteil durch unsere Ansprechpartner vor Ort in Würzburg, das Leistungsspektrum 
                                beider Marken und ein großes Experten-Netzwerk.</RevealText>
                                <RevealText classes="leading">Als Ihr zuverlässiger strategischer Partner im Bereich der Personalentwicklung gestalten wir mit Ihnen vertrauensvoll und nachhaltig die Zukunft Ihres Unternehmens und das Wachstum der Kompetenzen seiner Menschen.</RevealText>
                       </div>
                   </div>
               </div>

               <div className="row">
                <div className="col-6 marken_button d-flex align-items-center justify-content-center">
                    <a href="https://www.foxxu.de"><FoxxuLogoComponent/></a>
                </div>
                <div className="col-6 marken_button d-flex align-items-center  justify-content-center">
                <a href="http://my-conet.de/"><MyConetLogoComponent/></a>
                </div>
            </div>
               
           </div>
       </section>
    )
}