import React, { Fragment } from 'react';
import {RevealHeadlines, RevealCards} from './animation';
import Kunden from './Kunden';
import Ute from '../assets/Ute.png';
import Johannes from '../assets/Johannes.png';


const PartnerContent = [
    {
        name:"Ute Fuchs",
        firma: "foxxu",
        kontakt: {
            street:"Robert- Kirchhoff- Str.7",
            city:"97076 Würzburg",
            mobil:"+49 178-3369102",
            mail:"info@foxxu.de",
        },
        image: `${Ute}`
    },
    {
        name: "Johannes Walser",
        firma: "my-conet",
        kontakt: {
            street: "Robert- Kirchhoff- Str.7",
            city: "97076 Würzburg",
            mobil: "+49 151 266 19382",
            mail: "johannes.walser@my-conet.de"
        },
        image: `${Johannes}`
    }
];
export default function Partner(){
    return(

        <Fragment>
        <section className="mt-20">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2>
                            <span className="block__element"><RevealHeadlines>Ihre regionalen</RevealHeadlines></span> 
                            <span className="block__element"><RevealHeadlines>Ansprechpartner</RevealHeadlines></span>
                        </h2>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="partner">
                    <div className="row">
                        <div className="offset-xl-1"></div>
                    {PartnerContent.map((partner, index) => {
                        return(
                            <RevealCards classes="col-12 col-md-6 col-xl-3 partner__card" delay={index/2}>
                            
                                <div className="partner__card__img__wrapper">
                                    <div className="partner__card__bg"></div>
                                    <img src={partner.image} alt={partner.name}/>
                                    <div className="partner__card__head">
                                        <span>{partner.name}</span>
                                        <span>{partner.firma}</span>
                                    </div>
                                </div>
                                <div className="partner__card__content">
                                    <ul>
                                        <li>{partner.kontakt.street}</li>
                                        <li>{partner.kontakt.city}</li>
                                        <li>{partner.kontakt.mobil}</li>
                                        <li>{partner.kontakt.mail}</li>
                                    </ul>
                                </div>
                            
                            </RevealCards>
                        );
                    })}
                    </div>
                </div>
            </div>
        </section>
        <section className="kunden">
            <Kunden/>
        </section>
        </Fragment>
    )
}