import React from 'react';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

function Footer(){

    const date =  new Date();
    const year = date.getFullYear();
    const history = useHistory();

    const handleClick = (link) => {
        setTimeout(()=> {
            window.scroll(0,0);
            history.push(`${link}`)
        }, 600);
       
    }
    return(
        <div className="footer container-fluid d-flex justify-content-between">
            

              
                   <p> <a onClick={() => handleClick("/impressum")}>Impressum</a></p>
                
              
                    <p>© foxxu {year}</p>
               
               
                    <p><a onClick={()=> handleClick("/datenschutz")}>Datenschutzerklärung</a></p>
              
                 
        </div>  
    );
}


export default Footer;