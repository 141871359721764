import React, {Fragement, useEffect} from 'react';
import styled from 'styled-components';

import {ReactComponent as CastleComponent} from '../assets/Castle.svg';
import {ReactComponent as MyConetLogoComponent} from '../assets/my-conet.logo.svg';
import {ReactComponent as FoxxuLogoComponent} from '../assets/foxxu-logo.svg'; 
import gsap from 'gsap';


const Castle = styled(CastleComponent)`
    position: absolute;
    min-width: 100%;
    bottom:0;
    left:0; 
    right:0;
`;



export default function LandingPage(){

    useEffect(() => {


        const berg = document.querySelector(".berg");
        const bergLength = berg.getTotalLength();

        const castle = document.querySelector(".burg");
        const castleLength = castle.getTotalLength();

    
        berg.style.strokeDasharray = bergLength;
        castle.style.strokeDasharray = castleLength;
        
        gsap.from(".hero__title__position .block__element span", .6, {
            delay: 2,
            y:'150%',
            opacity: 0,
            skewY: 4,

        });
        gsap.from(".berg", 2, {strokeDashoffset: bergLength, ease: "power4.out"});
        gsap.from(".burg", 1, {strokeDashoffset: castleLength, ease: "power4.out"}
    
    );

        const tl = gsap.timeline();

        tl.from(".marken-logo", .7, {
            opacity: 0,
            y: '100%',
            stagger: {
                amount: .3
            } 
        })
        
    }, []);
    return(
       
            <div>

        <div className="hero">
            <div className="container-fluid hero__title">
                <div className="row hero__title__position d-flex align-items-end">
                    <div className="col-lg-11 offset-lg-1">
                        <h2>
                            <span className="block__element"><span>Personalentwicklung –</span></span>
                            <span className="block__element"><span>Mainfranken</span></span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid hero__castle">
                <Castle/>
            </div>
            <div className="container-fluid hero__sub">
                <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-6 offset-lg-1">
                        <div className="no-gutter col-12 hero__sub__p col-md-8">

                        <p>Ihr regionialer Kompetenzpartner in Mainfranken</p>
                        </div>
            
                    </div>
                    <div className="col-lg-2 offset-xl-0 offset-lg-0 col-4 offset-2 hero__marken">
                            <MyConetLogoComponent className="marken-logo"/>
                            
                    </div>
                    <div className="col-lg-2 col-4 hero__marken">
                        
                            <FoxxuLogoComponent className="marken-logo"/>
                    </div>
                </div>
            </div>

           
        </div>
        </div>
        
    );
}