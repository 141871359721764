import React, { Fragment, useEffect } from 'react';
import gsap from 'gsap';


export default function MethodenSeite({headline, content, list}){


   
    useEffect(() => {

        window.scrollTo(0,0);
        const tl = gsap.timeline();  



            tl.to(".overlay__loading", .4, {
                opacity: 0,
                display: 'none',
                delay: .6
            })
            .to(".method__site", {visibility: "visible"})
            .from(".method__site__headline__block span", .6, {
                opacity:0,
                y: '150%',
                ease: "power4.ease"
            })
            .from(".method__site__content__p", .6, {
                opacity: 0,
                y: 100,
                ease: "power4.ease"
            })
            .from(".method__site__list",.6, {
                y:100,
                opacity: 0,
                ease: "power4.ease",
                stagger: {
                    amount: .3
                }
            })
      
        
    }, []);

    return(
        <Fragment>
        <div className="overlay__loading"></div>
        <div className="container-fluid mt-menu method__site">
            <div className="row">
                <div className="offset-lg-1 col-lg-11">
                    <h3 className="method__site__headline"><span className="method__site__headline__block"><span>{headline}</span></span></h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-7 offset-lg-4 offset-md-3 method__site__content">
                    <p className="method__site__content__p">
                       {content} 
                    </p>
                </div>
            </div>
        </div>

        <div className="container-fluid method__site">
            <div className="row">
                {list.map(item => {
                    return (
                        <div className="col-10 offset-1 col-md-10 offset-md-1 method__site__list">
                        
                            <div className="offset-md-1 col-11 method__site__list__item">{item}</div>
                        
                        </div>
                    );
                })}
            </div>
        </div>
        </Fragment>

        
    );
}