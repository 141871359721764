import React, { useEffect, useState } from 'react';
import {RevealHeadlines, RevealText} from './animation';
import {ReactComponent as Arrow__Skew} from '../assets/Arrow_skew.svg';
import {useHistory} from 'react-router-dom';
const Words = ["analysieren", "beraten", "konzipieren", "qualifizieren", "evaluieren"];

export default function Way(){

    const [count, setCount] = useState(0);
    const history = useHistory();
    function handleAnimation(){
        
        if(count < Words.length-1){
            const newCount = count + 1;
            setCount(newCount);
        }else{
            const newCount = 0;

            setCount(newCount);
        }
        
        
    }

    const handleClick = ()=> {
        

        setTimeout(()=> {
            window.scroll(0,0);
            history.push(`/unser-weg-mit-ihnen`);
        }, 600);
    }
    return(
        <section className="mt-20 way">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2>
                        <span className="block__element"><RevealHeadlines>Unser Weg</RevealHeadlines></span>
                        <span className="block__element"><RevealHeadlines>mit Ihnen</RevealHeadlines></span>
                        </h2>
                    </div>
                    </div>
                    <div className="col-12 col-lg-11 offset-lg-1">
                        <div className="section__flow">
                            <RevealText classes="leading">Wir unterstützen Sie bei der Kompetenzentwicklung der Menschen in Ihrem 
                                Unternehmen. Dabei begleiten wir Sie in allen Phasen der Personalentwicklung.
                            </RevealText>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9 offset-lg-1">
                        <div className="link__action d-flex justify-content-between" onClick={handleClick}> 
                           <span className="d-flex shuffle_p">Wir  <span className="shuffleAnimation__wrapper"><span className="shuffleAnimation" onAnimationIteration={handleAnimation}>{Words[count]}</span></span>   </span> 
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

