import React, { useEffect } from 'react';
import {RevealHeadlines, RevealCards} from './animation';


import {useHistory} from 'react-router-dom';


const MethodsItems = [{name:"Assessment", path:"assessment"}, {name:"Coaching", path:"coaching"}, {name:"Training", path:"training"}, {name:"Workshops", path:"workshop"}];

export default function Methods(){

    const  history  = useHistory();
    function handleClick(item) {

        
        setTimeout(()=> {
            window.scroll(0,0);
            history.push(`/methods/${item.path}`);
        }, 600);
    };

    return(
        <section className="mt-20 methods">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2><span className="block__element"><RevealHeadlines>Unsere </RevealHeadlines></span>
                        <span className="block__element"><RevealHeadlines>Methoden </RevealHeadlines></span></h2>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                {MethodsItems.map((item,index) => {
                    return(
                       
                        <RevealCards delay={0} classes="col-12 col-lg-9 offset-lg-1">
                          <div className="methods__link" onClick={(event) => handleClick(item)}>
                        <div className="link__action d-flex justify-content-between"> 
                           <span className="d-flex shuffle_p">{item.name}   </span> 
                           
                        </div>
                  
                        </div>
                            </RevealCards>
                       
                    );
                })}
                </div>
                
            </div>

        </section>

    );
}
