import gsap from 'gsap';
import React, {useRef, useEffect} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const RevealHeadlines = ({children}) => {
    const revealRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline();
        gsap.from(revealRef.current, .6, {
            scrollTrigger: revealRef.current,
            y:200,
            opacity:0,
            skewX:-9,
            ease: 'power4.ease'
        })
    }, []);

    return(
        <span className="animated__word" ref={revealRef}>{children}</span>
    );
};


export const RevealCards = ({children, classes, delay}) => {
    const revealRef = useRef(null);

    useEffect(()  => {
        gsap.from(revealRef.current, 1, {
            scrollTrigger: revealRef.current,
            y: 100,
            delay: delay,
            opacity: 0,
            ease: 'power4.ease',
            
        })
    }, []);

    return(
        <div className={classes} ref={revealRef}>{children}</div>
    );
};


export const RevealText = ({children, classes}) => {
    const revealRef = useRef(null);

    useEffect(() => {
        gsap.from(revealRef.current, 2, {
            scrollTrigger: revealRef.current,
            y:100,
            opacity: 0,
            ease: 'power3.ease',
            stagger: {
                amount: .6
            }
        })
    }, []);

    return (<p className={classes} ref={revealRef}>
        {children}
    </p>)
}

