import React, { useEffect, Fragment } from 'react';
import gsap from 'gsap';
export default function Impressum(){
    
    useEffect(()=> {
        window.scroll(0,0);
        const tl = gsap.timeline()
        tl.to(".overlay__loading", .4, {
            opacity: 0,
            display: 'none',
            delay: .6
        })
    })
    return(
        <Fragment>
        <div className="overlay__loading"></div>
    <div className="impressum">
        <div className="container-fluid">
            <div className="row">
            <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="section__headline">
                        <h2>
                            <span className="block__element"><span>Impressum</span></span>     
                        </h2>
                    </div>
                    </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="impressum__absatz">
                    <h4>Angaben gemäß § 5 TMG:</h4>

                    <li>Dipl. Soz. Päd. Ute Fuchs</li>
                    <li>foxxu counseling & training</li>
                    <li>Robert-Kirchhoff-Str. 7</li>
                    <li>97076 Würzburg</li>

                    <h5>Kontakt</h5>
                    <li>Telefon: 0931 - 207 22 55</li>
                    <li>Telefax: 0931 - 207 22 56</li>
                    <li>E-Mail: info (at) foxxu.de</li>
                    </div>

                    <div className="impressum__absatz">
                    <h3>Haftungsausschluss (Disclaimer)</h3>
                    <h4>Haftung für Inhalte</h4>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    <h4>Haftung für Links</h4>
                    <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
derartige Links umgehend entfernen.</p>
                    </div>


                    <div className="impressum__absatz">
                    <h4>Urheberrecht</h4>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
</div>
                    
                </div>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

