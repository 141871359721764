import React, { useEffect, Fragment } from 'react';
import {RevealHeadlines, RevealCards} from './animation';
import gsap from 'gsap';
import {Link} from 'react-router-dom';

import Ute from '../assets/Ute.png';
import Johannes from '../assets/Johannes.png';

const PartnerContent = [
    {
        name:"Ute Fuchs",
        firma: "foxxu",
        kontakt: {
            street:"Robert- Kirchhoff- Str.7",
            city:"97076 Würzburg",
            mobil:"+49 178-3369102",
            mail:"info@foxxu.de",
        },
        image: `${Ute}`
    },
    {
        name: "Johannes Walser",
        firma: "my-conet",
        kontakt: {
            street: "Robert- Kirchhoff- Str.7",
            city: "97076 Würzburg",
            mobil: "+49 151 266 19382",
            mail: "johannes.walser@my-conet.de"
        },
        image: `${Johannes}`
    }
];
export default function Contact(){

    useEffect(()=> {
        window.scrollTo(0,0);
        const tl = gsap.timeline();

        tl.to(".overlay__loading", .4, {
            opacity: 0,
            display: 'none',
            delay: .6
        })
        .to(".method__site", {visibility: "visible"})
        .from(".section__headline", .6, {
            opacity:0,
            y: '150%',
            ease: "power4.ease"
        })
        .from(".contact__forms", .7, {
            opacity: 0,
            y: 100,
            ease: "power4.ease"
        })
        .from('.methods__link', .4, {
            opacity: 0,
            y: '100%',
            ease: "power4.ease"
        })
        .from('.contact__subhead', .4, {
            opacity: 0,
            y: '100%',
            ease: "power4.ease"
        })
        .from('.partner', .7,{
            opacity: 0,
            y: '100%',
            ease: "power4.ease"
            
        });

        
    });

    
    return(
        <Fragment>
        <div className="overlay__loading"></div>
            <div className="container-fluid mt-menu method__site">
            <div className="row">
                        <div className="col-12 col-lg-11 offset-lg-1">
                        <div className="section__headline">
                            <h2><span className="block__element"><RevealHeadlines>Kontakt </RevealHeadlines></span></h2>
                        </div>
                        </div>
                    </div>
            </div>
            <div className="container-fluid contact__forms">
                <div className="row">
                    <div className="col-lg-11 offset-lg-1">
                    <div className="methods__link">
                        <div className="link__action d-flex justify-content-between"> 
                           <span className="d-flex shuffle_p"><a href="mailto:mail@personalentwicklung-mainfranken">Schreiben Sie uns</a></span> 
                           
                        </div>
                  
                        </div>

                        <p className="contact__email">mail@personalentwicklung-mainfranken.de</p>
                    </div>
                </div>
                
            </div>
            <div className="container-fluid contact">
                <div className="col-lg-11 offset-lg-1">
                <h3 className="contact__subhead">Oder kontaktieren Sie ihre regionalen Ansprechpartner</h3>
                </div>
                
                <div className="partner">
                    <div className="row">
                        <div className="offset-xl-1"></div>
                    {PartnerContent.map((partner, index) => {
                        return(
                            <RevealCards classes="col-12 col-md-6 col-xl-3 partner__card" delay={index/2}>
                            
                                <div className="partner__card__img__wrapper">
                                    <div className="partner__card__bg"></div>
                                    <img src={partner.image} alt={partner.name}/>
                                    <div className="partner__card__head">
                                        <span>{partner.name}</span>
                                        <span>{partner.firma}</span>
                                    </div>
                                </div>
                                <div className="partner__card__content">
                                    <ul>
                                        <li>{partner.kontakt.street}</li>
                                        <li>{partner.kontakt.city}</li>
                                        <li>{partner.kontakt.mobil}</li>
                                        <li>{partner.kontakt.mail}</li>
                                    </ul>
                                </div>
                            
                            </RevealCards>
                        );
                    })}
                    </div>
                </div>
            </div>
            </Fragment>
    );
}
