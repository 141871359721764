

import React, { useState, Fragment, useEffect } from 'react';
import {ReactComponent as Logo} from '../assets/Logo.svg';
import gsap from 'gsap';
import { Link, NavLink } from 'react-router-dom';
import { toLower } from 'lodash';






export default function Headline() {

    const [menu, setMenu] = useState({menuOpen: false});
    const [animating, setAnimation] = useState(false);

    useEffect(() =>{


        const tl = gsap.timeline();

        if(menu.menuOpen === true){
            
            tl.to("nav", 0, {
                display: "block"
            })
            .to("nav", .70, {
                height: '100%'
                
            })
            .to(".menu__container span", .3, {
                delay: .5,
                y:0,
                opacity: 1,
                ease: "expo.out"
            })
            .to('.menu__container ul li', 0, {
                display: 'block',
            })
            .to(".menu__container ul li", .3, {
                y: 0,
                opacity: 1,
                ease: "ease4.out",
                stagger: {
                    amount: .3
                }
            })
        }
        else if(menu.menuOpen === false){
            tl.to(".menu__container ul li", .3,{
                y:  100,
                opacity: 0,
                ease: "ease4.out",
                stagger: {
                    amount: .3
                }   
            })
            .to(".menu__container ul li", 0, {
                display: 'none'
            })
            .to(".menu__container span", .3, {
                y: 100,
                opacity: 0,
                ease: "expo.out"
            })
            .to("nav", .7, {
                height: 0,
                
            })
            .to("nav", 0, {
                delay: .7,
                display: 'none'
            })
            
        }
    })

    const handleMenu = () => {
        

        if(animating){
            console.log(animating);
            return;
        }


        setAnimation(true);
        console.log(animating);
        setTimeout(()=> {
            
            setAnimation(false);
            console.log(animating);
        }, 4000);
        setMenu(menu.menuOpen ? {menuOpen: false} : {menuOpen: true});
    }
    return(
        <Fragment>
        <div className="headline">
            <div className="container-fluid headline__container">
                <div className="row headline__container">
                    <div className="offset-lg-1 col-lg-10 d-flex align-items-center justify-content-between">
                        <div className="headline__logo col-3"><Link to="/"><Logo/></Link></div>
                        <div className="headline__toggle">
                            <div className={`headline__burger ${menu.menuOpen}`} onClick={handleMenu}>
                                <span className="headline__burger__line line__1"></span>
                                <span className="headline__burger__line line__2"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <nav>
        <div className="menu__container container">
            <div className="row">
                <div className="col-lg-6">
                    <span>Menu</span>
                    <ul>
                        <li><NavLink to="/" onClick={handleMenu}>Überblick</NavLink></li>
                        <li><NavLink to="/markenallianz" onClick={handleMenu}>Unsere Markenallianz</NavLink></li>
                        <li><NavLink to="/unser-weg-mit-ihnen" onClick={handleMenu}>Unser Weg mit Ihnen</NavLink></li>
                        <li><NavLink to="/methods" onClick={handleMenu}>Unsere Methoden</NavLink></li>
                        <li><NavLink to="/kontakt" onClick={handleMenu}>Kontakt</NavLink></li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <span>Seiteninformationen</span>
                    <ul>
                        <li><NavLink to="/impressum" onClick={handleMenu}>Impressum</NavLink></li>
                        <li><NavLink to="/datenschutz" onClick={handleMenu}>Datenschutz</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
        </nav>
        </Fragment>
    );
    
}

